<template>
  <v-progress-circular
    class="async-loader"
    :rotate="-90"
    size="100"
    :width="2"
    indeterminate
    color="primary"
  >
    Loading...
  </v-progress-circular>
</template>

<script type="text/babel">
    import { mapGetters } from 'vuex';
    export default {
        name: "LoadingLogin",
        data() {
            return {
                ADlogin: false
            }
        },
        async created(){
          if(this.checkAuth){
                    var redirect = '/dashboard';
                    this.$router.push(redirect);
            }
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                checkAuth: 'authenticationStore/checkAuth',
                getUserType: 'authenticationStore/getUserType',
                getCompanyStatus : 'onboardingTabsStore/getCompanyStatus',
                getStatusMessage: 'statusStore/getMessage'
            })
        }   
    }

</script>
<style scoped>
  .async-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
</style>
