// authConfig.js
export const msalConfig = {
  auth: {
    clientId: "", // Replace with your Azure AD App Client ID
    authority: "",
    redirectUri: window.location.search, // Replace with your redirect URI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

export const loginRequest = {
  scopes: ["openid", "profile", "email", "api://36b5e0bc-bf45-48df-912c-7244d4123b62/access_as_user"] // Replace with your API's scope
};
