import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

let msalInstance = {}

export const signIn = async () => {
  msalInstance.loginRedirect(loginRequest);
};

export const initializeMSAL = async (payload) => {
  console.log('Initializing Azure Auth Service')
  msalConfig.auth.clientId = payload.clientId;
  msalConfig.auth.authority = 'https://login.microsoftonline.com/' + payload.tenantId;
  msalConfig.auth.redirectUri = payload.redirectUri;

  msalInstance = new PublicClientApplication(msalConfig);
}

export const handleRedirect = async () => {
  try {
    const response = await msalInstance.handleRedirectPromise();
    console.log("response :: ", response)
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const acquireToken = async () => {
  const account = msalInstance.getAllAccounts()[0];
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account
    });
    return response.accessToken;
  } catch (error) {
    console.error(error);
  }
};
